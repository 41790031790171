<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">{{ userData?.id ? $t('Customer editing') : $t('Adding a new customer') }}</div>
    </template>

    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pb-3">
<!--      <div v-if="item.role === constants.userRoles.customer || !item.role" class="p-col-12 p-md-12 p-d-flex p-flex-wrap">-->
<!--      <div v-if="!item.id" class="p-col-12 p-md-12 p-d-flex p-flex-wrap">-->
<!--      <div v-if="!item.have_archived_orders || (item.role === constants.userRoles.customer && ($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin)) || item.role === null" class="p-col-12 p-md-12 p-d-flex p-flex-wrap">-->
<!--      <b>{{ item.role }}</b>-->
<!--      <div v-if="" class="p-col-12 p-md-12 p-d-flex p-flex-wrap">-->
      <div v-if="canChangeUserType" class="p-col-12 p-md-12 p-d-flex p-flex-wrap">
        <div class="p-field-radiobutton p-mr-4">
          <RadioButton :id="'individual_checkbox' + (isSecondModal ? '--second-modal' : '')"
                       :disabled="!userCanEditCustomer"
                       value="Individual"
                       v-model="customerType"/>
          <label :for="'individual_checkbox' + (isSecondModal ? '--second-modal' : '')" class="radio-button-label">{{ $t('Individual') }}</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton :id="'company_checkbox' + (isSecondModal ? '--second-modal' : '')"
                       :disabled="!userCanEditCustomer"
                       value="Company"
                       v-model="customerType"/>
          <label :for="'company_checkbox' + (isSecondModal ? '--second-modal' : '')" class="radio-button-label">{{ $t('Company') }}</label>
        </div>
      </div>
    </div>

    <div class="p-formgrid p-grid" v-if="customerType === 'Company'">
      <div class="p-field p-col-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="customer_company_name"
                     :disabled="!userCanEditCustomer"
                     :class="{'p-invalid' : submitted && !userData.company_name}"
                     v-model.trim="userData.company_name" autocomplete="new-password"/>
          <label for="customer_company_name">{{ $t('Company name') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !userData.company_name">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="customer_reg_number"
                     :class="{'p-invalid' : submitted && (!userData.reg_number || regNumberIsTaken)}"
                     v-model.trim="userData.reg_number"
                     :disabled="!userCanEditCustomer"
                     @input="checkTakenRegNumberMatches"
                     @blur="checkTakenRegNumberMatches"
                     @paste="checkTakenRegNumberMatches"
                     autocomplete="new-password"/>
          <label for="customer_reg_number">{{ $t('Reg. number') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !userData.reg_number">{{ $t('Required field') }}</small>
        <small class="p-invalid" v-else-if="submitted && userData.reg_number && regNumberIsTaken">{{ $t('Registration number has already been taken') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputText id="customer_vat_number"
                     :disabled="!userCanEditCustomer"
                     v-model.trim="userData.vat_number"
                     autocomplete="new-password"/>
          <label for="customer_vat_number">{{ $t('VAT number') }}</label>
        </div>
      </div>
    </div>
    <div>
      <div class="p-formgrid p-grid">
        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="pi pi-user" />
            <InputText id="customer_name_first"
                       :disabled="!userCanEditCustomer"
                       :class="{'p-invalid' : submitted && !userData.first_name && customerType === 'Individual'}"
                       v-model.trim="userData.first_name"
                       autocomplete="new-password"/>
            <label for="customer_name_first">{{ $t('First name') }}<span v-if="customerType === 'Individual'" class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && !userData.first_name && customerType === 'Individual'">{{ $t('Required field') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="pi pi-user" />
            <InputText id="customer_name_last"
                       :disabled="!userCanEditCustomer"
                       v-model.trim="userData.last_name"
                       autocomplete="new-password"/>
            <label for="customer_name_last">{{ $t('Last name') }}</label>
          </div>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="phone-inputs-wrapper">
            <div class="p-float-label">
              <CustomDropdown id="customer_phone_code"
                              class="phone-code"
                              :class="{'p-invalid' : submitted && (!selectedPhoneCode || phoneNumberIsTaken)}"
                              v-model="selectedPhoneCode"
                              :disabled="!canChangeUserPhone || !userCanEditCustomer"
                              @change="checkTakenPhoneNumberMatches"
                              :clearSearchData="!visible"
                              :options="phoneCodesArray"
                              :filter="true"
                              optionLabel="country"
                              :filterFields="['country','value']"
                              :showClear="false"
                              autocomplete="new-password">
                <template #value="slotProps">
                  <div class="country-item country-item-value" v-if="slotProps.value">
                    <template v-if="slotProps.value.flagCode">
                      <span :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                      <span>{{ slotProps.value.value }}</span>
                    </template>
                    <template v-else>
                      <span>{{ slotProps.value.value }}</span>
                    </template>
                  </div>
                  <span v-else style="visibility: hidden">.</span>
                </template>
                <template #option="slotProps">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    <div>
                      <span v-if="slotProps.option.flagCode" :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                      <span >{{ slotProps.option.country }}</span>
                    </div>
                    <div style="font-weight: 500">{{ slotProps.option.value }}</div>
                  </div>
                </template>
              </CustomDropdown>
            </div>
            <div class="p-float-label p-input-icon-right">
              <i class="pi pi-phone" />
<!--              onkeypress="return event.charCode >= 48 && event.charCode <= 57"-->
              <InputText id="customer_phone"
                        :class="{'p-invalid' : submitted && (!userData.phone_number || phoneNumberIsTaken)}"
                        class="phone-number"
                        v-model.trim="userData.phone_number"
                        :disabled="!canChangeUserPhone || !userCanEditCustomer"
                        @keypress="numberInputOnKeyPress"
                        @input="phoneNumberOnInput"
                        @blur="checkTakenPhoneNumberMatches"
                        @paste="numberOnPaste"
                        autocomplete="new-password"/>
              <label for="customer_phone">{{ $t('Phone') }}<span class="warning-color">*</span></label>
            </div>
          </div>
          <small class="p-invalid" v-if="submitted && !userData.phone_number">{{ $t('Required field') }}</small>
          <small class="p-invalid" v-else-if="submitted && userData.phone_number && phoneNumberIsTaken">{{ $t('Phone number has already been taken') }}</small>
        </div>

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5" >
          <div class="p-float-label p-input-icon-right">
            <i class="ti-email" />
            <InputText id="customer_mail"
                       :disabled="!userCanEditCustomer"
                       :class="{'p-invalid' : submitted && userData.email && !emailIsValid}"
                       @input="validateEmail"
                       @paste="validateEmail"
                       v-model.trim="userData.email"
                       autocomplete="new-password"/>
            <label for="customer_mail">{{ $t('Email') }}</label>
          </div>
          <small class="p-invalid" v-if="submitted && userData.email && !emailIsValid">{{ $t('Value is not a valid email address') }}</small>
        </div>

<!--!! ATTENTION!   customer_email or company_email?-->
<!--        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5" >-->
<!--          <div class="p-float-label p-input-icon-right">-->
<!--            <i class="ti-email" />-->
<!--            <InputText id="customer_email" :class="{'p-invalid' : submitted && userData.email && !emailIsValid}" @input="validateEmail($event.target.value)" v-model.trim="userData.email" autocomplete="new-password"/>-->
<!--            <label for="customer_email">{{ $t('Email') }}</label>-->
<!--          </div>-->
<!--          <small class="p-invalid" v-if="submitted && userData.email && !emailIsValid">{{ $t('Value is not a valid email address') }}</small>-->
<!--        </div>-->

        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <CustomDropdown id="customer_language"
                            :disabled="!userCanEditCustomer"
                            :class="{'p-invalid' : submitted && !selectedLanguage}"
                            v-model="selectedLanguage"
                            @change="changeLanguage"
                            :options="languageValues"
                            :clearSearchData="!visible"
                            :filter="true"
                            optionLabel="label"
                            :showClear="false">
              <template #value="slotProps">
                <div class="country-item country-item-value" v-if="slotProps.value">
                  <span :class="'flag--dropdown-value flag flag-' + slotProps.value.flagCode" />
                  <span>{{slotProps.value.label}}</span>
                </div>
                <span v-else style="visibility: hidden">.</span>
              </template>
              <template #option="slotProps">
                <div>
                  <span :class="'flag--dropdown-option flag flag-' + slotProps.option.flagCode" />
                  <span>{{slotProps.option.label}}</span>
                </div>
              </template>
            </CustomDropdown>
            <label for="customer_language">{{ $t('Language') }}<span class="warning-color">*</span></label>
          </div>
          <small class="p-invalid" v-if="submitted && !selectedLanguage">{{ $t('Required field') }}</small>
        </div>

<!--          <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--            <div class="p-float-label">-->
<!--              <CustomDropdown id="customer_tax"-->
<!--                        v-model="selectedTax"-->
<!--                        @change="changeTax"-->
<!--                        :options="taxes"-->
<!--                              :preventFilter="true"-->
<!--                        :filter="false"-->
<!--                        optionLabel="value"-->
<!--                        :showClear="false">-->
<!--                &lt;!&ndash;            :placeholder="$t('Please select')"&ndash;&gt;-->
<!--                <template #value="slotProps">-->
<!--                  <div v-if="slotProps.value">-->
<!--                    <span>{{slotProps.value.value}}% ({{ slotProps.value.name }})</span>-->
<!--                  </div>-->
<!--                  <span v-else style="visibility: hidden">.</span>-->
<!--                </template>-->
<!--                <template #option="slotProps">-->
<!--                  <div>-->
<!--                    <span>{{slotProps.option.value}}% ({{ slotProps.option.name }})</span>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </CustomDropdown>-->
<!--              <label for="customer_tax">{{ $t('Tax') }}</label>-->
<!--            </div>-->
<!--          </div>-->
        <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5" v-if="customerType === 'Individual' && ($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin)">
          <div class="p-float-label">
            <!--            @input="checkDateIsValid"-->
            <!--            @blur="checkDateIsValid"-->
            <Calendar id="customer_birthdate"
                      :disabled="!userCanEditCustomer"
                      @show="toogleDropdownOverlayVisibility(true)"
                      @hide="toogleDropdownOverlayVisibility(false)"
                      @date-select="checkDateIsValid"
                      :class="{'p-invalid' : (userData.birthdate && !dateIsValid) && customerType === 'Individual' && ($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin)}"
                      v-model="userData.birthdate"
                      :dateFormat="settings.dateFormat"
                      :showIcon="true"
                      :monthNavigator="true"
                      :yearNavigator="true"
                      :selectOtherMonths="true"
                      autocomplete="new-password"/>
            <label for="customer_birthdate">{{ $t('Birthdate') }}</label>
          </div>
          <small class="p-invalid" v-if="(userData.birthdate && !dateIsValid) && customerType === 'Individual' && ($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin)">{{ $t('Invalid date format') }}</small>
        </div>

        <div v-else-if="customerType === 'Company'" class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="ti-email" />
            <InputText id="invoice_email"
                       :disabled="!userCanEditCustomer"
                       :class="{'p-invalid' : submitted && userData.invoice_email && !invoiceEmailIsValid}"
                       @input="validateInvoiceEmail"
                       @paste="validateInvoiceEmail"
                       v-model.trim="userData.invoice_email"
                       autocomplete="new-password"/>
            <label for="invoice_email">Invoice email</label>
          </div>
          <small class="p-invalid" v-if="submitted && userData.invoice_email && !invoiceEmailIsValid">{{ $t('Value is not a valid email address') }}</small>
        </div>
      </div>
      <div class="p-formgrid p-grid">
        <div v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"
             class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <InputNumber id="customer_services_discount"
                         :disabled="!userCanEditCustomer"
                         :locale="computedNumberInputLocale"
                         v-model="userData.services_discount"
                         mode="decimal"
                         suffix="%"
                         :min="0"
                         :max="100"
                         showButtons
                         :minFractionDigits="2"
                         :maxFractionDigits="2"
                         autocomplete="new-password"/>
            <label for="customer_services_discount">{{ $t('Services discount') }}</label>
          </div>
        </div>

        <div v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"
             class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <InputNumber id="customer_products_discount"
                         :disabled="!userCanEditCustomer"
                         :locale="computedNumberInputLocale"
                         v-model="userData.products_discount"
                         mode="decimal"
                         suffix="%"
                         :min="0"
                         :max="100"
                         showButtons
                         :minFractionDigits="2"
                         :maxFractionDigits="2"
                         autocomplete="new-password"/>
            <label for="customer_products_discount">{{ $t('Products discount') }}</label>
          </div>
        </div>


        <div v-show="($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin) && customerType === 'Company'"
             class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <InputNumber id="customer_days_to_pay"
                         :disabled="!userCanEditCustomer"
                         :locale="computedNumberInputLocale"
                         v-model="userData.days_to_pay"
                         :min="0"
                         :max="60"
                         showButtons
                         autocomplete="new-password"/>
            <label for="customer_days_to_pay">{{ $t('Days to pay') }}</label>
          </div>
        </div>

        <div v-show="($store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin) && customerType === 'Company'"
             class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label">
            <InputNumber id="customer_credit_limit"
                         :disabled="!userCanEditCustomer"
                         :locale="computedNumberInputLocale"
                         v-model="userData.credit_limit"
                         mode="decimal"
                         :min="0"
                         showButtons
                         :minFractionDigits="2"
                         :maxFractionDigits="2"
                         autocomplete="new-password"/>
            <label for="customer_credit_limit">{{ $t('Credit limit') }}</label>
          </div>
        </div>
        <div v-show="$store.state.branchData.use_customer_card" class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">
          <div class="p-float-label p-input-icon-right">
            <i class="ti-credit-card" />
            <InputText id="customer_card_number"
                       :disabled="!userCanEditCustomer"
                       v-model.trim="userData.customer_card"
                       autocomplete="new-password"/>
            <label for="customer_card_number">{{ $t('Client card') }}</label>
          </div>
        </div>
        <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
<!--            <div class="p-float-label p-input-icon-right">-->
<!--              <i class="ti-comment-alt" />-->
<!--              <Textarea id="customer_comment" v-model="userData.comment" rows="3" :autoResize="true" autocomplete="new-password"/>-->
<!--              <label for="customer_comment">{{ $t('Comment') }}</label>-->
<!--            </div>-->
          <LimitedCharsTextarea :submitted="submitted"
                                :disabled="!userCanEditCustomer"
                                :rows="3"
                                v-model="userData.comment"
                                :maxChars="commentMaxChars"
                                :autocomplete="'new-password'"
                                :id="'customer-comment'"
                                :label="$t('Comment')"/>
        </div>
<!--      DON'T DELETE!!!!!!!!!-->
<!--          <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12" v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin">-->
<!--            <AddressFieldset :modalLayer="modalLayer"-->
<!--                             :user="userData"-->
<!--                             :userId="+userData?.id"-->
<!--                             @update-item="updateAddress"-->
<!--                             @update-items="updateItems"/>-->
<!--          </div>-->
<!--      DON'T DELETE!!!!!!!!!-->
      </div>


<!--      DON'T DELETE!!!!!!!!!-->
<!--      <div style="display: none">-->
<!--        <div class="p-d-flex p-ai-center p-mt-1 p-pb-5">-->
<!--          <div>-->
<!--            <InputSwitch :disabled="!userCanEditCustomer" id="save_password" v-model="savePassword"/>-->
<!--          </div>-->
<!--          <label for="save_password" class="switch-label pointer">{{ $t('Save password') }}?</label>-->
<!--        </div>-->

<!--        <div class="p-formgrid p-grid" v-show="savePassword">-->
<!--          <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5" >-->
<!--            <div class="p-float-label">-->
<!--              <Password id="password" :disabled="!userCanEditCustomer" v-model.trim="userData.password" :feedback="false" toggleMask autocomplete="new-password"/>-->
<!--              <label for="password">{{ $t('Password') }}<span class="warning-color">*</span></label>-->
<!--            </div>-->
<!--            <small class="p-invalid" v-if="submitted && savePassword && !userData.password">{{ $t('Required field') }}</small>-->
<!--            <small class="p-invalid" v-else-if="submitted && savePassword && (userData.password && userData.password.length < 7)">Too small</small>-->
<!--            <small class="p-invalid" v-else-if="submitted && savePassword && userData.password_repeat !== userData.password">{{ $t('Passwords do not match') }}</small>-->
<!--          </div>-->

<!--          <div class="p-field p-col-12 p-sm-12 p-md-6 p-lg-6 p-mb-5">-->
<!--            <div class="p-float-label">-->
<!--              <Password id="password_repeat" :disabled="!userCanEditCustomer" v-model.trim="userData.password_repeat" :feedback="false" toggleMask autocomplete="new-password"/>-->
<!--              <label for="password_repeat">{{ $t('Repeat password') }}<span class="warning-color">*</span></label>-->
<!--            </div>-->
<!--            <small class="p-invalid" v-if="submitted && savePassword && !userData.password_repeat">{{ $t('Required field') }}</small>-->
<!--            <small class="p-invalid" v-else-if="submitted && savePassword && userData.password_repeat !== userData.password">{{ $t('Passwords do not match') }}</small>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      DON'T DELETE!!!!!!!!!-->
    </div>

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
  <CustomerExistsModal :visible="customerExistsModal"
                       :userId="userData?.id"
                       :items="foundCustomers"
                       @select-existing-customer="selectExistingCustomer"
                       @close="closeCustomerExistsModal"/>
</template>

<script>
// import AddressModal from '@/pages/users/components/AddressModal'
import httpClient from '@/services/http.services'
import phoneCodes from '@/translations/phoneCodes'
import settings from '@/settings'
import constants from '@/constants'
// import httpMixins from "@/mixins/httpMixins";
// import AddressFieldset from "@/pages/users/components/AddressFieldset";
import formatMixins from "@/mixins/formatMixins";
import overlayVisibilityMixins from "@/mixins/overlayVisibilityMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import CustomerExistsModal from "@/pages/users/components/CustomerExistsModal";
import permissionsMixins from "@/mixins/permissionsMixins";

export default {
  mixins: [ showErrorsMixins, formatMixins, overlayVisibilityMixins, permissionsMixins ],
  // components: { AddressFieldset },
  components: {  CustomerExistsModal },
  emits: ['close', 'update-item', 'update-items'],
  name: 'CustomerModal',
  props: {
    item: {
      type: Object,
    },
    visible: Boolean,
    taxes: Array,
    modalLayer: {
      type: Number,
      default: 1
    },
    isSecondModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedCustomer: null,
      foundCustomers: [],
      customerExistsModal: false,
      commentMaxChars: 500,
      regNumberIsTaken: false,
      takenRegNumber: null,
      phoneNumberIsTaken: false,
      takenPhoneNumber: null,
      dateIsValid: false,
      settings,
      defaultPhoneCode: null,
      // phoneCodes: null,
      phoneCodesArray: [],
      selectedPhoneCode: null,
      customerType: null,
      emailIsValid: false,
      invoiceEmailIsValid: false,
      // companyEmailIsValid: false,
      submitted: false,
      userData: {
        // company: {},
        // address: null,
        // password: null,
        // password_repeat: null,
      },
      // addressModal: false,
      // confirmDeleteAddressModal: false,
      // addressDraft: null,
      // userAddress: {},
      selectedLanguage: null,
      languageValues: settings.languages,
      savePassword: false,
      // selectedTax: null,
      constants,
      disableSaveButton: false,
      dataIsSending: false,
    }
  },
  mounted() {
    this.createPhoneCodesArray()
  },
  watch: {
    // customerType(value) {
    //   // this.customerType = value
    //   console.log(value)
    // },
    // 'userData.birthdate'(value) {
    //   if (!value) return
    //   // const date = new Date(this.userData.birthdate)
    //   const date = Date.parse(this.userData.birthdate)
    //   const date2 = +new Date(this.userData.birthdate)
    //   // const date = new Date(this.userData.birthdate).toISOString()
    //
    //
    //   // const date2 = +new Date(date)
    //   // const date = Date.parse(this.userData.birthdate)
    //   console.log(date, date2)
    // },
    item(value) {
      this.createItemData(value)
    },
    visible() {
      if (!this.visible) {
        this.disableSaveButton = false
        this.dataIsSending = false
        this.userData = {}
        this.selectedPhoneCode = null
        this.selectedLanguage = null
        this.submitted = false
        this.savePassword = false
        this.customerType = 'Individual'
        this.phoneNumberIsTaken = false
        this.takenPhoneNumber = null
        this.regNumberIsTaken = false
        this.takenRegNumber = null
      }
    },
    '$store.state.secondLayerIsOpened'(value) {
      if (this.modalLayer !== 1) return false
      if (!value) {
        if (this.customerExistsModal) {
          this.closeCustomerExistsModal()
        }
      }
    },
    '$store.state.thirdLayerIsOpened'(value) {
      if (this.modalLayer !== 2) return false
      if (!value) {
        if (this.customerExistsModal) {
          this.closeCustomerExistsModal()
        }
      }
    }
  },
  methods: {
    createItemData(value) {
      if (!value) {
        return false
      }
      this.userData = { ...value }

      if (value.type) {
        if (value.type === constants.userTypes.legal_entity) {
          this.customerType = 'Company'
        } else {
          this.customerType = 'Individual'
        }
      } else {
        this.customerType = 'Individual'
      }
      // this.customerType = +value?.type === constants.userTypes.legal_entity ? 'Company' : 'Individual'

      if (!value.first_name) {
        this.userData.first_name = this.$store.state.branchData.default_customer_name ?? ''
      }

      if (value.birthdate) {
        this.userData.birthdate = new Date(value.birthdate * 1000)
      }
      this.checkDateIsValid()

      if (value.credit_limit) {
        this.userData.credit_limit = +value.credit_limit
      }

      if (value.days_to_pay) {
        this.userData.days_to_pay = +value.days_to_pay
      }

      if (value.services_discount) {
        this.userData.services_discount = +value.services_discount
      }
      if (value.products_discount) {
        this.userData.products_discount = +value.products_discount
      }

      if (value.email) {
        this.validateEmail()
      }

      if (value.invoice_email) {
        this.validateInvoiceEmail()
      }

      if (value.language) {
        this.selectedLanguage = settings.languages.find((item) => item.value === value.language)
        this.userData.flag_code = this.selectedLanguage?.flagCode
      } else {
        this.selectedLanguage = this.computedDefaultLanguage
        this.userData.flag_code = this.selectedLanguage?.flagCode
      }

      if (+value.type === constants.userTypes.individual && value.phone_code) {
        const selectedPhoneCode = this.searchPhoneCode(value.phone_code)
        if (selectedPhoneCode) {
          this.selectedPhoneCode = selectedPhoneCode
        }
      } else if (+value.type === constants.userTypes.legal_entity && value.company_phone_code) {
        const selectedPhoneCode = this.searchPhoneCode(value.company_phone_code)
        if (selectedPhoneCode) {
          this.selectedPhoneCode = selectedPhoneCode
        }
      } else {
        this.selectedPhoneCode = this.defaultPhoneCode
      }

      // console.log(this.defaultPhoneCode)
      // this.phoneCodes = phoneCodes.map(code => {
      //   // return {
      //   //   code: code.code,
      //   //   country: `${code.countryName} (${code.originalCountryName})`,
      //   //   flagCode: code.flagCode
      //   // }
      //
      //   if (+code[2] === settings.phoneCodes.default) {
      //     this.defaultPhoneCode = {
      //       code: code[2],
      //       country: code[0],
      //       flagCode: code[1]
      //     }
      //   }

      // console.log(value.phone_code,code[2])
      // if (value.phone_code) {
      //   if (value.phone_code === +code[2]) {
      //     console.log(1)
      //     this.selectedPhoneCode = code
      //   }
      //
      // } else {
      //   if (!this.selectedPhoneCode) {
      //     console.log(2)
      //     this.selectedPhoneCode = this.defaultPhoneCode
      //   }
      //
      // }

      //   return {
      //     code: code[2],
      //     country: code[0],
      //     flagCode: code[1]
      //   }
      // })


      // if (value.phone_code) {
      //   this.selectedPhoneCode = phoneCodes.filter(code => code.code === value.phone_code)[0]
      // } else {
      //   this.selectedPhoneCode = phoneCodes.filter(code => code.isDefault)[0]
      // }


      if (+value.type === constants.userTypes.legal_entity) {
        if (value.credit_limit) {
          this.userData.credit_limit = +value.credit_limit
        }

        // if (value.company_phone_code) {
        //   phoneCodes.forEach(code => {
        //     if (+code[2] === +value.phone_code) {
        //       this.selectedPhoneCode = {
        //         code: code[2],
        //         country: code[0],
        //         flagCode: code[1]
        //       }
        //     } else {
        //       this.selectedPhoneCode = null
        //     }
        //   })
        // } else {
        //   this.selectedPhoneCode = phoneCodes.filter(code => code.isDefault)[0] ?? null
        // }


        if (value.company_phone_number) {
          this.userData.phone_number = value.company_phone_number
        }
        if (value.company_email) {
          this.userData.email = value.company_email
          this.validateEmail()
        }
      }
      this.selectedCustomer = {...this.userData}
    },
    // phoneCodeOnInput($event) {
    //   this.userData.phone_number = $event.value
    //   this.checkTakenPhoneNumberMatches()
    // },
    selectExistingCustomer(customerData) {
      this.createItemData(customerData)
    },
    closeCustomerExistsModal() {
      this.customerExistsModal = false
      // this.$store.commit('closeRequiredAppLayer')
      if (this.modalLayer === 2) {
        if (this.$store.state.thirdLayerIsOpened) {
          this.$store.commit('toggleThirdLayer', false)
        }
      } else if (this.modalLayer === 1) {
        if (this.$store.state.secondLayerIsOpened) {
          this.$store.commit('toggleSecondLayer', false)
        }
      }
    },
    checkTakenPhoneNumberMatches() {
      const code = this.selectedPhoneCode?.value
      const number = this.userData.phone_number

      if (!code || !number) return false

      const actualFullPhoneNumber = code + number
      this.phoneNumberIsTaken = !!(actualFullPhoneNumber === this.takenPhoneNumber)
    },
    checkTakenRegNumberMatches() {
      if (this.customerType === 'Company') {
        this.regNumberIsTaken = !!(this.userData.reg_number === this.takenRegNumber)
      } else {
        this.regNumberIsTaken = false
      }
    },
    checkDateIsValid() {
      const date = this.userData?.birthdate
      if (!date) return false
      this.dateIsValid = date instanceof Date && !isNaN(date)
    },
    numberOnPaste(event) {
      const text = event.clipboardData.getData('text/plain')

      let isTextContainsSpace = false
      const splittedPhoneNumber = text.split('')
      splittedPhoneNumber?.forEach(n => {
        if (n === ' ') {
          isTextContainsSpace = true
        }
      })

      if (text.replace(/\s/g, '').length === 0 || isNaN(text) || isTextContainsSpace) {
        event.preventDefault()
        return false
      }
      this.checkTakenPhoneNumberMatches()
    },
    numberInputOnKeyPress(event) {
      const res = event.charCode >= 48 && event.charCode <= 57
      if (!res) {
        event.preventDefault()
        return false
      }
    },
    phoneNumberOnInput() {
      if (this.submitted) this.checkTakenPhoneNumberMatches()
    },
    createPhoneCodesArray() {
      this.phoneCodesArray = phoneCodes.map(code => {
        if (code[2] === settings.phoneCodes.default) {
          this.defaultPhoneCode = {
            value: code[2],
            country: code[0],
            flagCode: code[1]
          }
        }

        return {
          value: code[2],
          country: code[0],
          flagCode: code[1]
        }
      })
    },
    searchPhoneCode(phoneCode) {
      if (!phoneCode) return false
      let selectedPhoneCode = null

      for(let i = 0; i < phoneCodes.length; i++) {
        const code = phoneCodes[i]
        if (code[2] === phoneCode) {
          selectedPhoneCode = {
            value: code[2],
            country: code[0],
            flagCode: code[1]
          }
          break
        }
      }
      return selectedPhoneCode
    },
    changeTax(event) {
      this.userData.tax_id = event.value.id
    },
    changeLanguage() {
      this.userData.flag_code = this.selectedLanguage?.flagCode
    },
    changeCustomerType(type) {
      if (this.customerType === type) {
        return false
      }
      this.customerType = type
    },
    async saveItem() {
      this.submitted = true
      if (!this.selectedLanguage ||
          (this.userData.comment && this.userData.comment.length > this.commentMaxChars)) {
        return false
      }
      if (this.customerType === 'Individual') {
        if ((this.userData.birthdate && !this.dateIsValid) || !this.userData.first_name || !this.userData.phone_number || (this.userData.email &&  !this.emailIsValid)) {
          return false
        }
      } else if (this.customerType === 'Company') {
        if (!this.userData.phone_number ||
            !this.userData.company_name ||
            !this.userData.reg_number ||
            (this.userData.email &&  !this.emailIsValid) ||
            (this.userData.invoice_email &&  !this.invoiceEmailIsValid)) {
          return false
        }
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      // if (this.savePassword) {
      //   if (!this.userData.password || !this.userData.password_repeat || (this.userData.password !== this.userData.password_repeat)) {
      //     return false
      //   }
      // }

      const user = {
        id: this.userData.id ?? null,
        type: this.customerType === 'Company' ? constants.userTypes.legal_entity : constants.userTypes.individual,
        // company_name: this.userData.company_name ? this.userData.company_name : null,
        // reg_number: this.userData.reg_number,
        // vat_number: this.userData.vat_number,
        company_name: this.customerType === 'Company' ? this.userData.company_name : null,
        reg_number: this.customerType === 'Company' ? this.userData.reg_number : null,
        vat_number: this.customerType === 'Company' ? this.userData.vat_number : null,
        company_email: this.customerType === 'Company' && this.userData.email ? this.userData.email : null,
        invoice_email: this.customerType === 'Company' && this.userData.invoice_email ? this.userData.invoice_email : null,
        company_phone_code: this.customerType === 'Company' && this.selectedPhoneCode && this.userData.phone_number ? this.selectedPhoneCode.value : null,
        company_phone_number: this.customerType === 'Company' && this.selectedPhoneCode && this.userData.phone_number ? this.userData.phone_number : null,
        // tax_id: this.selectedTax ? this.selectedTax.id : null,
        // tax_id: this.selectedTax?.id ?? null,
        credit_limit: this.customerType === 'Company' && this.userData.credit_limit ? this.userData.credit_limit : null,
        first_name: this.userData.first_name ? this.userData.first_name : null,
        middle_name: this.userData.middle_name ? this.userData.middle_name : null,
        last_name: this.userData.last_name ? this.userData.last_name : null,
        comment: this.userData.comment ? this.userData.comment : null,
        email: this.customerType !== 'Company' && this.userData.email ? this.userData.email : null,
        phone_code: this.customerType !== 'Company' && this.selectedPhoneCode && this.userData.phone_number ? this.selectedPhoneCode.value : null,
        phone_number: this.customerType !== 'Company' && this.selectedPhoneCode && this.userData.phone_number ? this.userData.phone_number : null,
        birthdate: this.userData.birthdate ? +new Date(this.userData.birthdate / 1000) : null,
        customer_card: this.userData.customer_card ? this.userData.customer_card : null,
        branch_id: this.$store.state.branchData.id,
        language: this.selectedLanguage?.value,
        flag_code: this.selectedLanguage?.flagCode,
        days_to_pay: this.customerType === 'Company' && this.userData.days_to_pay ? this.userData.days_to_pay : null,
        services_discount: this.userData.services_discount ? this.userData.services_discount : null,
        products_discount: this.userData.products_discount ? this.userData.products_discount : null,
        address: this.userData.address,
        isNew: false,
      }

      if (user.phone_code && user.phone_number) {
        user.full_phone_number = String(user.phone_code) + String(user.phone_number)
      }

      if (user.company_phone_code && user.company_phone_number) {
        user.company_full_phone_number = String(user.company_phone_code) + String(user.company_phone_number)
      }

      if (this.savePassword && this.userData.password) {
        user.password = this.userData.password
      }

      if (this.userData.id) {
        user.role = this.userData.role
        user.customerStatus = this.userData.customerStatus
        try {
          const { status, data } = await httpClient.post(`user/update`, user)
          // console.log(data)
          if (status === 200 && data?.success) {
            if (data.found_customers) {
              this.customerExistsModal = true
              this.$store.commit('openRequiredAppLayer')
              this.foundCustomers = data.found_customers
            } else {
              this.$emit('update-items', this.userData.id)
              // this.$emit('update-item', this.userData)
              this.$emit('update-item', user)
              this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: this.settings.toastLife});
              this.close()
            }
          } else if (data?.error) {
            // if (data.error.detail === 'Phone number has already been taken.') {
            //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Phone number has already been taken'), life: settings.toastLife})
            //   this.takenPhoneNumber = user.full_phone_number
            //   this.phoneNumberIsTaken = true
            // } else if (data.error.detail === 'Registration number has already been taken.') {
            //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Registration number has already been taken'), life: settings.toastLife})
            //   this.takenRegNumber = user.reg_number
            //   this.regNumberIsTaken = true
            // } else {
              this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
            // }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        user.role = constants.userRoles.customer
        this.userData.role = constants.userRoles.customer
        try {
          const { status, data } = await httpClient.post(`user/create`, user)
          // console.log(data)
          if (status === 201 && data?.success) {
            if (data.found_customers) {
              this.customerExistsModal = true
              this.$store.commit('openRequiredAppLayer')
              this.foundCustomers = data.found_customers
            } else {
              this.userData.id = data.id
              user.id = data.id
              user.isNew = true
              this.userData.isNew = true
              this.$emit('update-items', this.userData.id) // TRUE = user is new (to avoid update items)
              // this.$emit('update-item', this.userData)  // TRUE = user is new (to avoid update items)
              this.$emit('update-item', user)  // TRUE = user is new (to avoid update items)
              this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: this.settings.toastLife});
              this.close()
            }
          } else if (data?.error) {
            // if (data.error.detail === 'Phone number has already been taken.') {
            //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Phone number has already been taken'), life: 1750})
            //   this.takenPhoneNumber = user.full_phone_number
            //   this.phoneNumberIsTaken = true
            // } else if (data.error.detail === 'Registration number has already been taken.') {
            //   this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Registration number has already been taken'), life: 1750})
            //   this.takenRegNumber = user.reg_number
            //   this.regNumberIsTaken = true
            // } else {
              this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: this.settings.toastLife});
            // }
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
    // updateAddress(newAddress, userIsNotCreatedYet = false) {
    //   this.userData.address = { ...newAddress }
    //   if (this.modalLayer === 1 && !userIsNotCreatedYet) {
    //     this.$emit('update-items', this.userData.id)
    //   }
    //   this.$emit('update-item', this.userData)
    // },
    updateAddress(newAddress, updateItems) {
      this.userData.address = { ...newAddress }
      this.$emit('update-item', this.userData, updateItems)
    },
    updateItems() {
      this.$emit('update-items', this.userData.id)
    },
    validateEmail() {
      const email = this.userData?.email
      if (!email) return false
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!isCompany) {
      this.emailIsValid = re.test(email)
      // } else {
      //   this.companyEmailIsValid = re.test(email)
      // }
    },
    validateInvoiceEmail() {
      const email = this.userData?.invoice_email
      if (!email) return false
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // if (!isCompany) {
      this.invoiceEmailIsValid = re.test(email)
      // } else {
      //   this.companyEmailIsValid = re.test(email)
      // }
    }
  },
  computed: {
    canChangeUserType() {
      if (!this.item) return false

      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return true
      } else if (this.item.role === constants.userRoles.customer && this.userData.have_archived_orders) {
        return false
      } else {
        return true
      }
    },
    canChangeUserPhone() {
      if (!this.item) return false

      if (this.item.type === constants.userTypes.legal_entity) {
        return true
      }

      if (this.$store.state.user.role === constants.userRoles.superAdmin || this.$store.state.user.role === constants.userRoles.admin) {
        return true
      } else if (this.item.role === constants.userRoles.customer && this.userData.have_archived_orders) {
        return false
      } else {
        return true
      }
    },
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
    computedDefaultLanguage() {
      return settings.languages.find(language => language.isDefault) ?? null
    },
    // deleteAddressData() {
    //   if (this.userData.address?.id) {
    //     // return this.userData
    //     return (this.userData.address.address ? this.userData.address.address : '-') +
    //         (this.userData.address.address2 ? ', ' + this.userData.address.address2 : '') +
    //         (this.userData.address.postcode ? ', ' + this.userData.address.postcode : '') +
    //         (this.userData.address.city ? ', ' + this.userData.address.city : '') +
    //         (this.userData.address.state ? ', ' + this.userData.address.state: '') +
    //         (this.userData.address.country ? ', ' + this.userData.address.country.name : '')
    //   } else {
    //     return null
    //   }
    // },
  },

}
</script>

<style scoped lang="scss">
.p-button-text {
  width: auto;
}

.radio-button-label {
  padding: 6px;
  margin-left: 0;
  cursor: pointer;
}
</style>